<template>
    <div>
        <div v-for="(item, index) in this.weipagesData" :key="index">
            <!-- 文本 -->
            <div v-if="item.flag == 'text'">
                <mytextshow :textdata="item"></mytextshow>
            </div>
            <!-- 富文本 -->
            <div v-if="item.flag == 'richtext'">
                <myrichtextshow :richtextdata="item"></myrichtextshow>
            </div>
            <!-- 图片 -->
            <div v-if="item.flag == 'picture'">
                <mypictureshow :picturedata="item" :pictureflages="weiformflages"></mypictureshow>
            </div>
            <!-- 按钮 -->
            <div v-if="item.flag == 'button'">
                <mybuttonshow :buttondata="item" :buttonflages="weiformflages"></mybuttonshow>
            </div>
            <!-- 表单 -->
            <div v-if="item.flag == 'form'">
                <myformshow :formdata="item" :formflages="weiformflages"></myformshow>
            </div>
        </div>
    </div>
</template>

<script>
import mytextshow from "@/components/weipages/subshow/textshow/textshow.vue";
import myrichtextshow from "@/components/weipages/subshow/richtextshow/richtextshow.vue"
import mypictureshow from "@/components/weipages/subshow/pictureshow/pictureshow.vue"
import mybuttonshow from "@/components/weipages/subshow/buttonshow/buttonshow.vue"
import myformshow from "@/components/weipages/subshow/formshow/formshow.vue"

import { reactive } from "vue";

export default {
    name: "weipagespromocode",
    props: ['weipagesdata','weiformflages'],
    setup(props) {
        const weipagesData = reactive(props["weipagesdata"]);

        return {
            weipagesData,
        };
    },
    methods: {},
    watch: {
        // 监听-数据更新
        weipagesdata(val) {
            this.weipagesData = val;
        },
    },
    components: {
        mytextshow,
        myrichtextshow,
        mypictureshow,
        mybuttonshow,
        myformshow,

    },
};
</script>

<style scoped>
</style>

